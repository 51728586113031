import React, { useCallback, useState, useEffect } from "react";
import Styled from "styled-components";

import { File } from "../../components/Input";
import Button from "../../components/Button";

import Notification, { Error } from "../../modules/Notifications";
import Confirm from "../../modules/AlertConfirm";

import useLoading from "../../hooks/useLoading";

import api from "../../service/api";

const Section = Styled.section`
    & .row {
        display: flex;
        justify-content: center;
    }
`;

function Index() {
    const [ecobriteImage, setEcobriteImage] = useState({ file: {} });

    const [ecobriteFile, setEcobriteFile] = useState({ file: {} });

    const { loading } = useLoading();

    const indexSettings = useCallback(() => {
        async function indexSettings() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/configuracoes-produtos-mercados"
                );

                response.data.forEach((item) => {
                    switch (item.type) {
                        case 0:
                            const ecobriteImage = {
                                file: item.object.image,
                                id: item.id,
                                preview: `${api.defaults.baseURL}${item.object.image.url}`,
                            };

                            const ecobriteFile = {
                                file: item.object.file,
                                id: item.id,
                                preview: `${api.defaults.baseURL}${item.object.file.url}`,
                            };

                            setEcobriteImage(ecobriteImage);
                            setEcobriteFile(ecobriteFile);
                            break;

                        default:
                            break;
                    }
                });

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        indexSettings();
    }, [loading]);

    useEffect(() => {
        indexSettings();
    }, [indexSettings]);

    const uploadFile = async (file) => {
        let data = new FormData();
        data.append(`files`, file);
        const response = await api.post("/upload", data);
        return response.data[0];
    };

    async function handleSubmitEcobrite() {
        const idLoading = loading();

        try {
            if (Object.keys(ecobriteImage.file).length === 0) {
                return Notification("warning", "Imagem Ecobrite é obrigatório");
            }

            if (Object.keys(ecobriteFile.file).length === 0) {
                return Notification("warning", "PDF Ecobrite é obrigatório");
            }

            const image = await uploadFile(ecobriteImage.file);

            const file = await uploadFile(ecobriteFile.file);

            await api.post("/configuracoes-produtos-mercados", {
                type: 0,
                object: {
                    image,
                    file,
                },
            });

            indexSettings();

            Notification("success", "Configuração Ecobrite cadastrada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Section className="col-sm-12 col-md-6">
            <h2>Ecobrite</h2>
            <div className="row">
                <File
                    label="Imagem*"
                    width="200"
                    accept=".jpg, .png"
                    file={ecobriteImage}
                    onUpload={(files) => {
                        const file = files[0];
                        if (ecobriteImage.id) {
                            Confirm(
                                "Alterar Imagem Ecobrite",
                                "Tem certeza que deseja alterar ?",
                                async () => {
                                    const idLoading = loading();

                                    await api.delete(
                                        `/upload/files/${ecobriteImage.file.id}`
                                    );

                                    const response = await uploadFile(file);

                                    await api.put(
                                        `/configuracoes-produtos-mercados/${ecobriteImage.id}`,
                                        {
                                            type: 0,
                                            object: {
                                                image: response,
                                                file: ecobriteFile.file,
                                            },
                                        }
                                    );

                                    indexSettings();
                                    Notification(
                                        "success",
                                        "Imagem Ecobrite alterado"
                                    );

                                    loading(idLoading);
                                }
                            );
                        } else {
                            setEcobriteImage({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }
                    }}
                    onDeleteFile={() => {
                        if (ecobriteImage.id) {
                            Notification("warning", "Não é possivel remover");
                        } else {
                            setEcobriteImage({});
                        }
                    }}
                />
                <File
                    label="PDF*"
                    width="200"
                    height="250px"
                    accept=".pdf"
                    fileType="pdf"
                    file={ecobriteFile}
                    onUpload={(files) => {
                        const file = files[0];
                        if (ecobriteFile.id) {
                            Confirm(
                                "Alterar PDF Ecobrite",
                                "Tem certeza que deseja alterar ?",
                                async () => {
                                    const idLoading = loading();

                                    await api.delete(
                                        `/upload/files/${ecobriteFile.file.id}`
                                    );

                                    const response = await uploadFile(file);

                                    await api.put(
                                        `/configuracoes-produtos-mercados/${ecobriteFile.id}`,
                                        {
                                            type: 0,
                                            object: {
                                                image: ecobriteImage.file,
                                                file: response,
                                            },
                                        }
                                    );

                                    indexSettings();
                                    Notification(
                                        "success",
                                        "PDF Ecobrite alterado"
                                    );

                                    loading(idLoading);
                                }
                            );
                        } else {
                            setEcobriteFile({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }
                    }}
                    onDeleteFile={() => {
                        if (ecobriteFile.id) {
                            Notification("warning", "Não é possivel remover");
                        } else {
                            setEcobriteFile({});
                        }
                    }}
                />
            </div>
            {!ecobriteImage.id && (
                <div>
                    <Button variant="success" onClick={handleSubmitEcobrite}>
                        Enviar
                    </Button>
                </div>
            )}
        </Section>
    );
}

export default Index;
