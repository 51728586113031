import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import Img from "../Img";

import { Error } from "../../modules/Notifications";

import useLoading from "../../hooks/useLoading";

import Api from "../../service/api";

const Index = () => {
    const [spotlights, setSpotlights] = useState([]);

    const { loading } = useLoading();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: false,
    };

    useEffect(() => {
        async function indexStoplight() {
            const idLoading = loading();
            try {
                const response = await Api.get("/configuracoes-destaques", {
                    headers: {
                        Authorization: "",
                    },
                });

                const spotlights = response.data.map((item) => {
                    return {
                        url: `${Api.defaults.baseURL}${item.image.url}`,
                        id: item.id,
                    };
                });

                setSpotlights(spotlights);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }
        indexStoplight();
    }, [loading]);

    return (
        <Slider {...settings}>
            {spotlights.map((spot) => (
                <Img
                    key={spot.id}
                    src={spot.url}
                    className="shadow-none border-0"
                    alt="Destaque Noordhen Higiene Aplicada"
                    width="100%"
                />
            ))}
        </Slider>
    );
};

export default Index;
