import React, { useCallback, useState, useEffect } from "react";
import Styled from "styled-components";

import { File } from "../../components/Input";
import Button from "../../components/Button";

import Notification, { Error } from "../../modules/Notifications";
import Confirm from "../../modules/AlertConfirm";

import useLoading from "../../hooks/useLoading";

import Api from "../../service/api";

const Section = Styled.section`
    & div div .row {
        display: flex;
        justify-content: center;
    }
`;

function Index() {
    const [stateCreate, setStateCreate] = useState(false);

    const [objects, setObjects] = useState([]);

    const [object1, setObject1] = useState({ image: {}, file: {} });
    const [object2, setObject2] = useState({ image: {}, file: {} });
    const [object3, setObject3] = useState({ image: {}, file: {} });
    const [object4, setObject4] = useState({ image: {}, file: {} });

    const { loading } = useLoading();

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-produtos-mercados?type=3"
                );

                if (response.data.length === 0) {
                    return setStateCreate(true);
                }

                setStateCreate(false);

                const objects = response.data[0].object;
                setObjects(objects);

                const object1 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[0].file.id,
                        file: objects[0].file,
                        preview: `${Api.defaults.baseURL}${objects[0].file.url}`,
                    },
                    image: {
                        file: objects[0].image,
                        preview: `${Api.defaults.baseURL}${objects[0].image.url}`,
                    },
                };
                setObject1(object1);

                const object2 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[1].file.id,
                        file: objects[1].file,
                        preview: `${Api.defaults.baseURL}${objects[1].file.url}`,
                    },
                    image: {
                        file: objects[1].image,
                        preview: `${Api.defaults.baseURL}${objects[1].image.url}`,
                    },
                };
                setObject2(object2);

                const object3 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[2].file.id,
                        file: objects[2].file,
                        preview: `${Api.defaults.baseURL}${objects[2].file.url}`,
                    },
                    image: {
                        file: objects[2].image,
                        preview: `${Api.defaults.baseURL}${objects[2].image.url}`,
                    },
                };
                setObject3(object3);

                const object4 = {
                    id: response.data[0].id,
                    file: {
                        id: objects[3].file.id,
                        file: objects[3].file,
                        preview: `${Api.defaults.baseURL}${objects[3].file.url}`,
                    },
                    image: {
                        file: objects[3].image,
                        preview: `${Api.defaults.baseURL}${objects[3].image.url}`,
                    },
                };
                setObject4(object4);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => index(), [index]);

    function handleValidate() {
        if (Object.keys(object1.image).length === 0) {
            return { status: false, message: "Imagem Pratic 1 é obrigatório" };
        }

        if (Object.keys(object1.file).length === 0) {
            return { status: false, message: "PDF Pratic 1 é obrigatório" };
        }

        if (Object.keys(object2.image).length === 0) {
            return { status: false, message: "Imagem Pratic 2 é obrigatório" };
        }

        if (Object.keys(object2.file).length === 0) {
            return { status: false, message: "PDF Pratic 2 é obrigatório" };
        }

        if (Object.keys(object3.image).length === 0) {
            return { status: false, message: "Imagem Pratic 3 é obrigatório" };
        }

        if (Object.keys(object3.file).length === 0) {
            return { status: false, message: "PDF Pratic 3 é obrigatório" };
        }

        if (Object.keys(object4.image).length === 0) {
            return { status: false, message: "Imagem Pratic 4 é obrigatório" };
        }

        if (Object.keys(object4.file).length === 0) {
            return { status: false, message: "PDF Pratic 4 é obrigatório" };
        }

        return { status: true };
    }

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);

        const response = await Api.post("/upload", data);

        loading(idLoading);
        return response.data[0];
    };

    async function create() {
        const idLoading = loading();

        try {
            const validate = handleValidate();
            if (!validate.status) {
                return Notification("warning", validate.message);
            }

            let objectData = [];
            const obj1Image = await uploadFile(object1.image.file);
            const obj1File = await uploadFile(object1.file.file);
            objectData.push({ image: obj1Image, file: obj1File });

            const obj2Image = await uploadFile(object2.image.file);
            const obj2File = await uploadFile(object2.file.file);
            objectData.push({ image: obj2Image, file: obj2File });

            const obj3Image = await uploadFile(object3.image.file);
            const obj3File = await uploadFile(object3.file.file);
            objectData.push({ image: obj3Image, file: obj3File });

            const obj4Image = await uploadFile(object4.image.file);
            const obj4File = await uploadFile(object4.file.file);
            objectData.push({ image: obj4Image, file: obj4File });

            await Api.post("/configuracoes-produtos-mercados", {
                type: 3,
                object: objectData,
            });

            index();
            Notification("success", "Configuração Pratic cadastrada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Section>
            <h2>Pratic</h2>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-sm-12">Pratic 1</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept=".jpg, .png"
                            file={object1.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object1.id) {
                                    Confirm(
                                        "Alterar Imagem Pratic 1",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();
                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object1.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;
                                                objectData[0].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object1.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem Pratic 1 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject1({
                                        ...object1,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object1.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject1({
                                        ...object1,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={object1.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object1.id) {
                                    Confirm(
                                        "Alterar PDF Pratic 1",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();
                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object1.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[0].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object1.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF Pratic 1 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject1({
                                        ...object1,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object1.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject1({
                                        ...object1,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-sm-12">Pratic 2</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept=".jpg, .png"
                            file={object2.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object2.id) {
                                    Confirm(
                                        "Alterar Imagem Pratic 2",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();
                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object2.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[1].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object2.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem Pratic 2 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject2({
                                        ...object2,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object2.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject2({
                                        ...object2,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={object2.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object2.id) {
                                    Confirm(
                                        "Alterar PDF Pratic 2",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();
                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object2.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;
                                                objectData[1].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object2.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF Pratic 2 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject2({
                                        ...object2,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object2.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject2({
                                        ...object2,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-sm-12">Pratic 3</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept=".jpg, .png"
                            file={object3.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object3.id) {
                                    Confirm(
                                        "Alterar Imagem Pratic 3",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object3.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[2].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object3.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem Pratic 3 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject3({
                                        ...object3,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object3.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject3({
                                        ...object3,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={object3.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object3.id) {
                                    Confirm(
                                        "Alterar PDF Pratic 3",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object3.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[2].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object3.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF Pratic 3 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject3({
                                        ...object3,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object3.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject3({
                                        ...object3,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <h3 className="col-sm-12">Pratic 4</h3>
                    <div className="row">
                        <File
                            className="col"
                            label="Imagem*"
                            width="200"
                            accept=".jpg, .png"
                            file={object4.image || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object4.id) {
                                    Confirm(
                                        "Alterar Imagem Pratic 4",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();

                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object4.image.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;

                                                objectData[3].image = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object4.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "Imagem Pratic 4 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject4({
                                        ...object4,
                                        image: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object4.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject4({
                                        ...object4,
                                        image: {},
                                    });
                                }
                            }}
                        />
                        <File
                            className="col"
                            label="PDF*"
                            width="200"
                            height="250px"
                            accept=".pdf"
                            fileType="pdf"
                            file={object4.file || {}}
                            onUpload={(files) => {
                                const file = files[0];

                                if (object4.id) {
                                    Confirm(
                                        "Alterar PDF Pratic 4",
                                        "Tem certeza que deseja alterar ?",
                                        async () => {
                                            const idLoading = loading();
                                            try {
                                                await Api.delete(
                                                    `/upload/files/${object4.file.file.id}`
                                                );

                                                const response =
                                                    await uploadFile(file);

                                                let objectData = objects;
                                                objectData[3].file = response;

                                                await Api.put(
                                                    `/configuracoes-produtos-mercados/${object4.id}`,
                                                    {
                                                        object: objectData,
                                                    }
                                                );

                                                index();

                                                Notification(
                                                    "success",
                                                    "PDF Pratic 4 alterada"
                                                );

                                                loading(idLoading);
                                            } catch (error) {
                                                Error(error);

                                                loading(idLoading);
                                            }
                                        }
                                    );
                                } else {
                                    setObject4({
                                        ...object4,
                                        file: {
                                            file,
                                            preview: URL.createObjectURL(file),
                                        },
                                    });
                                }
                            }}
                            onDeleteFile={() => {
                                if (object4.id) {
                                    Notification(
                                        "warning",
                                        "Não é possivel remover"
                                    );
                                } else {
                                    setObject4({
                                        ...object4,
                                        file: {},
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {stateCreate && (
                <div>
                    <Button variant="success" onClick={create}>
                        Enviar
                    </Button>
                </div>
            )}
        </Section>
    );
}

export default Index;
