import React from "react";
import Slider from "react-slick";

const Index = ({ children }) => {
    return (
        <Slider
            settings={{
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                cssEase: "linear",
                pauseOnHover: false,
            }}
        >
            {children}
        </Slider>
    );
};

export default Index;
