import React, { useState, useCallback, useEffect } from "react";
import Styled from "styled-components";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import Button from "../Button";

import Api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import Notification, { Error } from "../../modules/Notifications";

const GridButtton = Styled.div`
    margin-top: 1rem!important;

    @media (max-width: 750px) {
        display:flex;
        justify-content: center;
    }
`;

const Container = Styled.div`
        margin-top: 2rem!important;
    `;

function Index() {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [id, setId] = useState(0);

    const { loading } = useLoading();

    async function handleOnSubmit() {
        const idLoading = loading();

        try {
            const html = document.querySelector("#editor-html").value;

            if (id === 0) {
                await Api.post("/configuracoes-termos-de-usos", { html });

                Notification("success", "Configurado termo de uso inputs");
                return;
            }

            await Api.put(`/configuracoes-termos-de-usos/${id}`, { html });

            Notification(
                "success",
                "Configuração termo de uso inputs atualizada"
            );

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get("/configuracoes-termos-de-usos");

                if (response.data.length === 0) {
                    return;
                }

                setId(response.data[0].id);

                const html = response.data[0].html;
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                        contentBlock.contentBlocks
                    );
                    const editorState =
                        EditorState.createWithContent(contentState);
                    setEditorState(editorState);
                }

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => {
        index();
    }, [index]);

    return (
        <Container>
            <div>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(value) => {
                        setEditorState(value);
                    }}
                />
                <textarea
                    id="editor-html"
                    style={{ visibility: "hidden", position: "absolute" }}
                    disabled
                    value={draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                    )}
                />
            </div>
            <GridButtton>
                <Button variant="success" onClick={handleOnSubmit}>
                    Enviar
                </Button>
            </GridButtton>
        </Container>
    );
}

export default Index;
