import React, { useEffect, useState } from "react";

import Api, { publicRequest } from "../../service/api";

import { Error } from "../../modules/Notifications";

import useLoading from "../../hooks/useLoading";

import { Container, Grid } from "./styles";

function Index() {
    const [accept, setAccept] = useState(false);

    const { loading } = useLoading();

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-popup-cookies",
                    publicRequest
                );

                if (response.data.length === 0) {
                    loading(idLoading);

                    return setAccept(true);
                }

                if (!response.data[0].ativo) {
                    setAccept(true);
                }

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    if (accept) {
        return <></>;
    }

    return (
        <Container>
            <Grid>
                <p>
                    A Noordhen Brasil utiliza cookies e outras tecnologias
                    semelhantes para melhorar a experiência em nossa plataforma.
                    Ao continuar navegando, você concorda com as condições
                    previstas na nossa Política de Privacidade. Para mais
                    informações, consulte{" "}
                    <a href="/politica-de-privacidade" target="_blank">
                        aqui
                    </a>
                    .
                </p>
                <button
                    onClick={() => {
                        setAccept(true);
                    }}
                >
                    Li e concordo
                </button>
            </Grid>
        </Container>
    );
}

export default Index;
