import { useEffect, useState, useCallback } from "react";

import { Error as notificationError } from "../../../modules/Notifications";

import useLoading from "../../../hooks/useLoading";

import api, { publicRequest } from "../../../service/api";

function useProducts({ client }) {
    const [aplications, setAplications] = useState([]);

    const [applicationProducts, setApplicationProducts] = useState([]);

    const [selectedAplication, setSelectedAplication] = useState("");

    const [product, setProduct] = useState("");

    const [products, setProducts] = useState([]);

    const { loading } = useLoading();

    const filterProductsExclusive = useCallback(
        (products = []) => {
            if (typeof client === "undefined") return;

            const filterData = products.filter((item) => {
                if (item.exclusivo) {
                    if (item.clientes.length > 0) {
                        item.clientes = item.clientes.map((item) => item.id);

                        return item.clientes.includes(client.id);
                    }

                    return false;
                }

                return true;
            });

            return filterData;
        },
        [client]
    );

    const serializeProducts = useCallback((products = []) => {
        return products.map((item) => {
            const { id: ID, Titulo, aplicacaos: Aplicacoes, image } = item;

            const serializedAplicacoes = Aplicacoes.map(
                (aplicacao) => aplicacao.id
            );

            return {
                ID,
                Titulo,
                Aplicacoes: serializedAplicacoes,
                image,
            };
        });
    }, []);

    const orderProducts = (products = []) => {
        return products.sort((a, b) => {
            if (a.linha.ordem > b.linha.ordem) {
                return 1;
            }

            if (a.linha.ordem < b.linha.ordem) {
                return -1;
            }

            return 0;
        });
    };

    useEffect(() => {
        const indexProducts = async () => {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/produtos?_limit=-1",
                    publicRequest
                );

                const orderedProducts = orderProducts(response.data);

                const filterData = filterProductsExclusive(orderedProducts);

                const serializedData = serializeProducts(filterData);

                if (!selectedAplication) return setApplicationProducts([]);

                const filteredData = serializedData.filter((item) =>
                    item.Aplicacoes.includes(selectedAplication)
                );

                setApplicationProducts(filteredData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        };

        if (typeof client === "undefined") return;

        indexProducts();
    }, [
        client,
        selectedAplication,
        filterProductsExclusive,
        serializeProducts,
        loading,
    ]);

    useEffect(() => {
        const indexAplications = async () => {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/aplicacaos?_sort=Aplicacao:ASC&_limit=-1",
                    publicRequest
                );

                const serializedData = response.data.map((item) => ({
                    value: item.id,
                    label: item.Aplicacao,
                }));

                setAplications(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        };

        indexAplications();
    }, [loading]);

    useEffect(() => {
        const index = async () => {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/produtos?_sort=Titulo:ASC&_limit=-1",
                    publicRequest
                );

                const filterData = filterProductsExclusive(response.data);

                const serializedData = filterData.map((item) => ({
                    value: item.id,
                    label: item.Titulo,
                }));

                setProducts(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        };

        index();
    }, [client, filterProductsExclusive, loading]);

    function handleSelectAplication(event) {
        setSelectedAplication(event.value);
    }

    function handleSelectProduct(product) {
        setProduct(product);
        const pos = document.getElementById("product").offsetTop;

        window.scrollTo({
            top: pos,
            behavior: "smooth",
        });
    }

    return {
        aplications,
        selectedAplication,
        applicationProducts,
        client: !typeof client === "undefined" ? client.id : "",
        product,
        products,
        handleSelectAplication,
        handleSelectProduct,
    };
}

export default useProducts;
